@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@600&family=PT+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.contactsmini__container {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-auto-rows: 1fr; 
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 0px 45px; 
    grid-template-areas: 
      "contactsmini__name contactsmini__adress contactsmini__number contactsmini__links"; 
  }
  .contactsmini__name { grid-area: contactsmini__name; }
  .contactsmini__adress { 
    grid-area: contactsmini__adress;
    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 30px;
    font-weight: 700;
    min-height: 50px;
  }
  .contactsmini__number {
    grid-area: contactsmini__number; 
    font-family: 'PT Sans', sans-serif;
 
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
  }
  .contactsmini__number-email-1{
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    color: #e13228;
  }
  .contactsmini__links { grid-area: contactsmini__links; }
  .contactsmini__wrapper{
    padding: 70px 20px 50px 20px;
  }
  .contactsmini__number-phone{
    cursor: pointer;
    text-decoration: none;
    font-weight: bolder;
    font-family: 'PT Sans', sans-serif;
    text-decoration: none;
    color: rgb(13, 13, 13);
    margin-bottom: 10px;
  }
.contactsmini__wrapper{
  width: 80%;
  margin: auto;
}
.contactsmini__name-top{
  display: flex;
  font-family: 'Golos Text', sans-serif;
  font-size: 19px;
  line-height: 0.8;
  
}
.contactsmini__name-logo{
  margin-right: 15px;

}
.contactsmini__name-desc{
  font-size: 13px;
  line-height: 20px;
  max-width: 170px;
  margin-left: 50px;
  font-family: 'PT Sans', sans-serif;
}
.contactsmini__links{
  display: flex;
  flex-direction: column;
}
.contactsmini__links-item{
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  font-family: 'PT Sans', sans-serif;
  padding: 5px 0; 
}
.contactsmini__links-item:hover{
  color: #2ea363;
}
.yandex__map{

  height: 500px;
  margin: auto;
  width: 100%;
}
.Map__container{
  width: 80%;
  margin: auto;
  
}
.Contacts__tittle{
  margin-top: 0px;
  font-family: 'PT Sans', sans-serif;
  font-weight: 500;
  padding-top: 10px;
  font-weight: 700;
  font-size: 38px;
  padding-bottom: 15px;
  margin: auto;
}
.Contacts__list{
  display: flex;
  list-style: none;
}
.contacts__subcontainer{
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  font-family: 'PT Sans', sans-serif;

}
.contacts__subcontainer-tittle{
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 50px;
    margin-top: 30px;
}
.contacts__subcontainer-adress{
  font-size: 19px;
  margin-bottom: 30px;
}
.contacts__subcontainer-phone{
  text-decoration: none;
  color: black;
  display: block;
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  font-size: 22px;
  width: fit-content;
}
.contacts__subcontainer-mail{
  display: block;
  font-family: 'PT Sans', sans-serif;

  font-size: 22px;
  width: fit-content;
  margin-top: 20px;
}
.contacts__subcontainer-phone:hover{
  color: #2ea363;
  cursor: pointer;
}
.pdf__icon{
  height: 50px;
}
.contacts__subcontainer-card{
  display: flex;
  height: 134px;
  border: 1px solid #b2b6be;
  padding: 0px 50px;
  align-items: center;
  text-decoration: none;
  font-family: 'PT Sans', sans-serif;
  margin-top: 30px;
  transition: 0.3s;
  
}
.contacts__subcontainer-card:hover{
  box-shadow: 0px 0px 30px 5px rgba(34, 60, 80, 0.2);
}
.contacts__card-block{
  color: black;
  text-decoration: none;
  
}
.contacts__card-block:hover{
  color: #2ea363;
  text-decoration: none;

}
.contacts__card-block p{
  color: rgb(83, 83, 83);
  text-decoration: none;

}
@media(max-width:700px){
  .contactsmini__container {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-auto-rows: 1fr; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 24px 24px; 
    grid-template-areas: 
      "contactsmini__name contactsmini__adress"
      "contactsmini__number contactsmini__links"; 
  }
  .contactsmini__name { grid-area: contactsmini__name; }
  .contactsmini__adress { grid-area: contactsmini__adress; }
  .contactsmini__number { grid-area: contactsmini__number; }
  .contactsmini__links { grid-area: contactsmini__links; }
}
@media(max-width:650px){
  .contactsmini__container {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-auto-rows: 1fr; 
    grid-template-columns: 1fr ; 
    grid-template-rows: 1fr 1fr 1fr 1fr; 
    gap: 24px 24px; 
    grid-template-areas: 
      "contactsmini__name" 
      "contactsmini__adress"
      "contactsmini__number"
       "contactsmini__links"; 
  }
  .contactsmini__name { grid-area: contactsmini__name; }
  .contactsmini__adress { grid-area: contactsmini__adress;
  font-size: 16px;
  
  }
  .contactsmini__number { grid-area: contactsmini__number; }
  .contactsmini__links { grid-area: contactsmini__links; }
  .contacts__subcontainer{
    flex-direction: column;
  }
}