@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.catalog__container{
    background-color: #ecedf0;
    width: 100%;
    height: 60px;
}
.catalog__list{
    max-width: 1170px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    
}
.catalog__list-item{
    width: 25%;
    
    display: flex;
    align-items:center ;
    justify-content: center;
    line-height: 1.5;
    border-bottom: 0;
    border-top: 0px;
    
    height: 60px;
    font-family: 'Ysabeau SC', sans-serif;

    text-decoration: none;
    font-size: 1.3rem;
    color: #212529;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    
}
.catalog__list-item-link{
    text-decoration: none;
    color: #212529;
    width: 100%;
    padding: 20px 0px ;

}
.catalog__list-item:hover{
    color: #e13228;
    background-color: rgb(246,247,250);
}
@media(max-width:580px){
    .catalog__list{
        flex-direction: column;
                
    }
    .catalog__container{
        height: 240px;
    }
    .catalog__list-item{
        width: 100%;
        border-left: 0;
        border-right: 0;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        padding-left: 20px;
    }
    .catalog__list-item-link{
        justify-content: flex-start;
        display: flex;
        text-align: start;
    }
}
@media(max-width:800px){
    .catalog__list-item-link{
        font-size: 18px;

    }
}