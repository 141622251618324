@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@600&family=PT+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.Articles__link-item{
    background-color: #fff;
    width: auto;
    padding: 20px 0px;
    overflow: hidden;
}

.Articles__wrapper{
    background-color: #f6f7fa;
    width: 80%;
    margin: auto;
}
.Articles_link{
    text-decoration-color: transparent;

}
.Articles_link:hover{
    box-shadow: -1px 4px 38px 8px rgba(34, 60, 80, 0.27);
    transition: 0.5s;
}
.Articles__container{
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows:  repeat(6,1fr);
}
.Article__container-item{
    max-width: 1100px;
    font-size: 19px;
    color: #717681;
    padding-top: 10px;
    padding-bottom: 35px;
    font-family: 'Ysabeau SC', sans-serif;

}
.Article__container__subtittle{
    max-width: 1100px;
    font-size: 32px;
    color: #717681;
    padding-top: 17px;
    padding-bottom: 15px;
    font-family: 'Ysabeau SC', sans-serif;

}
.Articles__tittle{
    margin-top: 0px;
    font-family: 'Ysabeau SC', sans-serif;

    font-weight: 500;
    padding-top: 10px;
    font-weight: 700;
    font-size: 38px;
    padding-bottom: 15px;

}
.Articles__link-item-tittle{
    padding: 29px 46px 10px 40px;
    font-size: 22px;
    line-height: 26px;
    font-family: 'Ysabeau SC', sans-serif;

    color: black;

}
.Articles__link-item-date{
    margin-right: 50px;
    font-size: 13px;
    text-align: right;
    color: #717681;
    font-family: 'Ysabeau SC', sans-serif;

    align-self: flex-end;
}
.Articles__link-item-text{
    font-size: 15px;
    line-height: 22px;
    color: #717681;
    font-family: 'Ysabeau SC', sans-serif;

    margin: 10px 46px 20px 40px;
    overflow: hidden;
    height: 90px;
    position: relative;
}
tr:nth-child(odd){
    background-color: #fff;
}
tr:nth-child(even){
    background-color: transparent;
}
td{
    background-color: transparent;
}

tr{
    height: 30px;
    color: #000;
    font-size: 16px;
    text-align: left;
    border-left: none;
    font-family: 'PT Sans', sans-serif;
}
.Article__container-list{
    list-style: none;
    max-width: 1100px;
    font-size: 17px;
    color: #717681;

    padding-bottom: 35px;
    font-family: 'Ysabeau SC', sans-serif;

    padding-left: 20px;
}
.Article__container-list-item{
    padding-bottom: 10px;
}
@media(max-width:900px){
    .Articles__container{
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows:  repeat(8,1fr);
    }
}
@media(max-width:650px){
    .Articles__container{
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(1,1fr);
        grid-template-rows:  repeat(16,1fr);
    }
}