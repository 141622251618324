@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');

.popup1{
    height: 100em;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
    top: -500px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position:fixed;
    z-index: 1200;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}
.popup1.active{
    opacity: 1;
    pointer-events: all;
}
.popup1__content{
    padding: 20px;
    border-radius: 12px;
    background-color: white;
    width: 400px;
    display: flex;
    justify-items: center;
    align-items: center; 
    transform: scale(0.5);
    transition: 0.4s all;
}
.popup1__content.active{
    transform: scale(1);
}
.popup__wrapper{
    font-family: 'PT Sans', sans-serif;
    margin-left: 60px;
    margin-right: 60px;
}
.popup__tittle{
    font-size: 24px;    
    font-weight: 700;
    line-height: 42px;
    margin: 15px 0 20px;
    color: #0b162b;
}
.popup__subtittle{
    font-size: 14px;
    color: #0b162b;
    margin-bottom: 30px;
}
.popup__form{
    display: flex;
    flex-direction: column;
}
.popup__form-item{
    
    background: none;
    border: none;
    border-bottom: 1px solid #b2b6be;
    font-size: 16px;
    color: #0b162b;
    border-radius: 0;
    padding: 0 0 5px 5px;
    height: 35px;
    margin: 20px 0;
    max-width: 320px;

}
.popup__form-item:focus{
        outline: 2px solid lightblue;
}
.popup__warning{
    color: #717681;
    font-size: 11px;
    line-height: 13px;
    padding: 25px 0 20px 0;
    max-width: 275px
}
.popup__button{
    background: #e13228;
    outline: none;
    width: 150px;
    height: 48px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito Sans', sans-serif;
    color: #fff;
    font-weight: 400;
    outline: 0;
}
.popup__crossbar{
    width: 40px;
    font-size: 30px;
    font-weight: 700;
    line-height: 1;
    color: #444444;
    text-shadow: 0 1px 0 #fff;
    position: absolute;
    align-self: flex-start;
    justify-self: flex-start;
    
    background-color: transparent;
    border: 0;
    z-index: 100;
}
.popup__crossbar:hover{
    color: #171717;
}
.success__message{
    font-size: 18px;
    color: green;
    display: block;
}
.success__message.nonactive{
    display: none;
}
@media (max-width:500px) {
    .popup1__content{
        width: 350px;
    }
}
@media (max-width:370px) {
    .popup1__content{
        width: 300px;
    }
    .popup__wrapper{
        margin-left: 30px;
    }
}