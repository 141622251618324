@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.MPcatalog__tab__list{
    display: flex;
    list-style: none;
    margin-bottom: 20px;

}
.MPcatalog{
    padding-bottom: 50px;
    margin-top: 30px;
}

.MPcatalog__tab__list-item{
    margin-left: 40px;
    
 
}
.MPcatalog__button{
    font-size: 22px;
    background-color: transparent;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;   
    color: #b2b6be;
    padding-bottom: 3px;text-align: start;
    transition: 0.3s;
}
.MPcatalog__button:hover{
    color: #9da0a8;
}
.MPcatalog__tab{
    width: 80%;
    margin: auto;
}
.MPactive{
    border-bottom: 2px solid  #e13228;
    color: #1a1b1e;
}
.MPactive:hover{
    color: #50505d;
}
@media(max-width:800px){
    .MPcatalog__tab__list{
        display: flex;
        flex-wrap: wrap;
    flex-direction: column;
    }
}