
@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@600&family=PT+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
header{
    height: 90px;
    position: fixed;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.422);
    width: 100%;
    justify-content: center;
    z-index: 1005;

}
.header.active{
    background-color: rgb(0,0,0);
    border-bottom: 3px solid rgb(0,0,0);
}
.header__container{
    height: 72px;    
    width: 100%;
    display: flex;
    
    margin-right: 20px;
    align-items: flex-end;
    justify-content: space-around;

}
.logo{
    display: flex;
}
.logo-image{
    width: 48px;
    height: 47px;
    margin-right: 15px;
}
.logo__name{
    color: #fff;
    font-size: 22px;
    line-height: 24px;
    font-family: 'Golos Text', sans-serif;
    text-decoration: none;
    
}
.business{
    color: rgb(223,223,223);
    font-size: 15px;
    max-width: 130px;
    line-height: 22px;
    white-space: normal;
    margin-left: 26px;
    font-family: 'PT Sans', sans-serif;

}
.menu__list{
    list-style: none;
    display: flex;
    color: #fff;
}
.menu__item{
    padding-right: 15px;
}
.menu-link{
    color: #fff;
    text-decoration: none;
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    transition: 0.3s;
}
.menu-link:hover{
    color: #b2b6be;
}
.contacts__wrapper{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.menu{
    display: none;
    transition: 0.5s;
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
}
.menu.nonactive{
    display: none;
    
}
.demenu.nonactive{
    display: none;

}
.demenu{
    display: block;
    transition: 0.5s;
}
.headerphone{
    display: flex;
    flex-direction: column;

        margin-bottom: 5px;
    
}
.phone-number{
    font-weight: bolder;
    font-family: 'PT Sans', sans-serif;
    text-decoration: none;
    color: rgb(255,255,255);
    transition: 0.3s;
}
.phone-number:hover{
    color: #b2b6be;
}
.phone-text{
    font-family: 'PT Sans', sans-serif;
    color: #e13228;
    text-align: center;
}
@media(max-width:1200px){
    .business{
    display: none;    
    }

}
@media(max-width:1000px){
    .menu{
        display: block;
    }
    
    .menu__list{
        display: none;
    }
    .logo__name{
        font-size: 18px;        
    }

}
@media (max-width:650px){
    header{
        height: 70px;
    }
    .header__space{
        height: 70px;
    }
}
@media(max-width:500px){
    .logo__name{
        font-size: 16px;        
    }
    .phone-number{
        font-size: 14px;
        
    }
    .headerphone{
        margin-bottom: 5px;
    }
    .menu{
        scale: 0.8;
    }
    header{
        height: 50px;
        width: 100%;
    }
    .header__container{
        height: auto;
    }
    .header__space{
        height: 50px;
    }
}
@media(max-width:400px){
    .logo__name{
        display: none;
    }

}
