
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.gS__container-item{
    width: fit-content;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'PT Sans', sans-serif;
}
.gS__container-item:hover{

    box-shadow: 0px 5px 64px 21px rgba(99, 107, 114, 0.15);
}
.gs__item__bottom-tittle{
    color: black;
    text-decoration: none;

    font-size: 18px;
    line-height: 26px;
    margin: 20px 0 10px;


    
}
.gs__item__bottom-tittle:hover{
        color: #e13228;
}
.gs__item__bottom-power{

    color: #000000;
    min-height: 40px;
    margin-bottom: 5px;
    font-size:19px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.gs__item__bottom-power-st{
    font-size: 14px;
}
.gS__item__bottom{
    padding-left: 20px;
}
.gs__item__bottom-power-nd{
    font-size: 14px;
}
.gs__item__bottom-tittle{
    font-size: 18px;
    min-height: 78px;
    color: #717681;
}

.gS__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.gs__item__top-pic{
    max-height: 180px;
    height: auto;
    max-width: 100%;
    width: fit-content;
    margin: auto;
}
@media(max-width:500px){
    .gs__item__top-pic.denyodca45{
        max-width: 200px;
    }
}
.gS__item__top{
    padding: 33px 26px 37px 26px;
    border-bottom: 1px solid #ecedf0;
    height: 180px;
    align-items: center;
    
    display: flex;
    justify-content: center;
}


.gs__item__bottom-price-t{
    color: #717681;
    font-weight: normal;
    font-size: 17px;
    display: inline-block;
    width: 80px;

}
.gs__item__bottom-price{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.gS__item__bottom1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.gs__item__bottom-price-c{

    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #2ea363;
}
.gs__item__bottom-button{
    background-color: transparent;
    border-left: 0px;
    border-right:0px ;
    border-bottom: 0px;
    border-top: 1px solid #ecedf0;
    font-family: 'Ysabeau SC', sans-serif;

    height: 47px;
    line-height: 47px;
    color: #1a1b1e;
    font-weight: 700;
    font-size: 16px;
    width:100%;
    transition: 0.5s;
}
.gs__item__bottom-button:hover{
    background-color:#2ea363 ;
    color: #fff;
}
.gs__bottom__link{
    text-decoration: none;
    display: block;
    height: 48px;
    margin: 0 auto;
    width: 182px;
    text-align: center;
    line-height: 48px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    margin-top: 40px;
    background: #b2b6be;
    transition: 0.5s;
}
.gs__bottom__link:hover{
    box-shadow: 1px 1px 55px 9px rgba(34, 60, 80, 0.23);
}
