@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.partnerslider__container{
    width: 100%;
    margin: auto;
    padding: 45px 0;
    background-color:  #f6f7fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.partnerslider__item{
    width: 100px;
}
.partnersslider__tittle{
    background: #f6f7fa;
    font-weight: 500;
    font-size: 26px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'PT Sans', sans-serif;
}