@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.video__block{
    width:100%;
    overflow: hidden;
    min-width: 100%;
    max-height: 80vh;
}
.video{
    width: 100%;
}
.banner__bg{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.banner__container{
    
    max-width: 1120px;
    z-index: 900;
    position: absolute;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: auto;
}
.banner__img{
    width: 350px;
    height: 100px;
    margin: 25px;
    
    display: flex;

}
.banner__text__block{    
    padding-left: 20px;
    margin: 25px;   
    font-family: 'PT Sans', sans-serif;
    font-weight: 500;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.banner__text__block-tittle{

    font-size: 50px;
    line-height: 60px;

    color: #fff;
}
.banner__text__block-text{
    padding-left: 5px;
    font-size: 18px;
    line-height: 26px;
    color: #fff;
    
    max-width: 450px;
}
@media(max-width:1000px){
    .banner__container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 150px;
        text-align: center;
    }
}
@media(max-width:800px){
    .banner__text__block-tittle{
        font-size: 50px;
        margin-bottom: 0;
    }
    .banner__text__block-text{
        font-size: 16px;

    }
    .banner__img{
        width: 200px;
        margin-bottom: 0;
    }
}
@media(max-width:650px){
    .banner__text__block{
        margin-top: 0;
    }
    .banner__img{
        width: 200px;
        height: 60px;
        margin-top: 0;

    }
    .banner__text__block-text{
        font-size: 14px;
    }
}
@media(max-width:500px){
    .banner__text__block{
        margin: 0;
        padding: 0;
    }
    .banner__container{
        padding-top: 70px;
    }
    .banner__text__block-tittle{
        font-size: 25px;
        height: 30px    ;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media(max-width:412px){
    .banner__container{
        padding-top: 40px;
    }
    .banner__img{
        width: 150px;
        height: 40px;
    }
    .banner__text__block-text{
        height: 75px;
        line-height: 1.5;
    }
}
@media(max-width:350px){
    .banner__container{
        padding-top: 20px;
    }
}