@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@600&family=PT+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.item__wrapper{
    width: 80%;
    margin: auto;
    font-family: 'PT Sans', sans-serif;
}
.item__wrapper p{
    font-family: 'Ysabeau SC', sans-serif;

}
.item__content__main{
    width: 80%;
    border: 1px solid #e0e0e0;
    padding: 10px 15px;
    font-size: 16px;
}
.card__table-wrapper{
    width: 100%;
}
h2{
    font-size: 19px;
    font-weight: 400;
}
.item__tittle{
    margin-top: 0px;
    font-family: 'PT Sans', sans-serif;
    font-weight: 500;
    padding-top: 10px;
    font-weight: 700;
    font-size: 38px;
    padding-bottom: 15px;
}
.item__content__bottom-button{
    border:1px solid #000000;
    color: #000;
    width: 196px;
    height: 60px;
    font-weight: 600;
    transition: 0.3s;
        margin-bottom: 20px;
}
.item__content__bottom-button:hover{
    background-color: #2ea363;
    color: #fff;
    border: 1px solid #b2b6be;
}
.item__content__top-button{
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #e0e0e0;
    padding: 2px 8px;
    cursor: pointer;
    position: relative;
    top: 1px;
    margin: 0 6px 0 0;
    border-width: 1px 1px 0;
    border-radius: 11px 11px 0 0;
    font-size: 18px;
    line-height: 48px;
    transition: 0.5s;

}
.item__content__top-button.buttonActive{
    background-color: #ecedf0;
}
.item__image{
    max-width: 920px;
    height: 350px;
    padding-right: 50px;
}

.item__image.podi{
    width: 920px;
    height: 700px;
}
.item__container{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.item__content{
    width: 100%;
}
@media(max-width:1500px){
    .item__image.podi{
        width: 750px;
        height: 500px;
    }
}
@media(max-width:1280px){
    .item__image.podi{
        width: 650px;
        height: 450px;
    }
}
@media(max-width:1050px){
    .item__image.podi{
        width: 600px;
        height: 400px;
    }
}
@media(max-width:900px){
    .item__container{
        flex-direction: column;
    }
    .item__image.manmain{
        width: 300px;
    }
    .item__image.podi{
        width: 500px;
        height: 350px;
    }
}
@media(max-width:600px){
    .item__image{
        width: 300px;
        height: 230px;
    }
    .item__image.podi{
        width: 300px;
        height: 200px;
    }
    .item__image.manmain{
        width:200px;
    }
}