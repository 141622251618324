@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.moreInfo__container {
    padding: 40px 0;
    width: 80%;
    margin: auto;
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-auto-rows: 1fr; 
    grid-template-columns: 1fr 2fr; 
    grid-template-rows: 1fr; 
    gap: 0px 15px; 
    grid-template-areas: 
      "moreinfo__row1 moreinfo__row2"; 
  }
  .moreinfo__row1 { grid-area: moreinfo__row1; }
  .moreinfo__row2 { grid-area: moreinfo__row2; }
.moreinfo__form{
    display: flex;
    
}
.moreinfo__form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.moreinfo__form-item{
    
    background: none;
    border: none;
    border-bottom: 1px solid #b2b6be;
    font-size: 16px;
    color: #0b162b;
    border-radius: 0;
    padding: 0 0 5px 5px;
    height: 35px;
    margin: 20px 0;
    max-width: 220px;

}
.moreinfo__form-item.textarea1{
    min-width: 250px;
    min-height: 80px;
    margin-bottom: 0;
}
.moreinfo__form-div{
    display: flex;
    flex-direction: column;
}
.moreinfo__form-item.inftel{
    border-bottom: none;
}

.moreinfo__form-item:focus{
        outline: 2px solid #007bff;
}
.moreinfo__row1-tittle{
    font-family: 'PT Sans', sans-serif;
    font-weight: bold;
    font-size: 42px;
    color: #1a1b1e;
    line-height: 46px;
    margin: 15px 0 20px;
}
.moreinfo__row1-subtittle{
    font-family: 'PT Sans', sans-serif;
    font-size: 18px;
    color: #0b162b;
}
.moreinfo__warning{
    color: #717681;
    font-family: 'PT Sans', sans-serif;
    font-size: 11px;
    line-height: 13px;
    padding: 25px 0 20px 0;
    max-width: 275px
}
.moreinfo__button{
    background: transparent;
    outline: none;
    width: 150px;
    height: 48px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito Sans', sans-serif;
    color: #000000;
    font-weight: 400;
    transition: 0.5s;
    border: 1px solid black;
}
.moreinfo__button:hover{
    color: #fff;
    background-color: #2ea363;
    
}
.moreinfo__button:active{
    scale: 0.90;
}
@media(max-width:1000px){
    .moreInfo__container {
        display: grid; 
        grid-auto-columns: 1fr; 
        grid-auto-rows: 1fr; 
        grid-template-columns: 1fr; 
        grid-template-rows: 0.3fr 1fr; 
        gap: 0px 24px; 
        grid-template-areas: 
          "moreinfo__row1"
          "moreinfo__row2"; 
          
      }
      .moreinfo__row1 { grid-area: moreinfo__row1; }
      .moreinfo__row2 { grid-area: moreinfo__row2; }
      .moreinfo__row1-tittle{
        font-size: 35px;
      }
}
@media(max-width:650px){
    .moreinfo__form{
        grid-template-columns: repeat(1, 0.8fr);
    }
}
