@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@600&family=PT+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');

.generatory__container{
    display: flex;
    flex-wrap: wrap;
    margin: auto;
}
.gS__container-item1{
    width: fit-content;
    background-color: #fff;
    display: flex;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    border: 2px solid #ecedf0;
}
.generator__tittle{
    margin-top: 0px;
    font-family: 'PT Sans', sans-serif;
    font-weight: 500;
    padding-top: 10px;
    font-weight: 700;
    font-size: 38px;
    padding-bottom: 15px;
}
.generatory__wrapper{
    width: 80%;
    margin: auto;
    font-family: 'PT Sans', sans-serif;
}
.generatory__text-item{
    font-size: 15px;
    line-height: 22px;
    color: #717681;
    margin-bottom: 15px;
}
.generatory__text{
    margin-top: 40px;
}
.generatory__text-tittle{
    font-size: 2rem;
    color: #717681;
    margin-bottom: 20px;
}
.generatory__text-list{
    padding-left: 20px;
}
