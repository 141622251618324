@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.shortabout__wrapper{
    background-color: rgb(246,247,250);
    position: relative;
    width: 100%;
    z-index: 5;
    margin-bottom: 40px;
    padding-left: 10%;
}
.shortabout-image{
    position: absolute;
    z-index: -1;
    justify-self: center;
}
.shortabout__tittle{
    font-size: 32px;
    font-weight: 500;
    width: 100%;
    padding-top: 10px;
    margin-bottom: 21px;
    text-align: left;
    font-family: 'PT Sans', sans-serif;
}
.section__shortabout{
    width: 80%;
    margin-top: 30px;
    padding-bottom: 35px;
    margin: LEFT;
}
.profit__list{
    display: grid;
    display: grid; 
    grid-template-columns: repeat(4, 1fr); 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    list-style: none;
    
    
    margin: auto;
    background-image: url(../img/airman-bg.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
}
.profit__list-item{
    display: flex;
    flex-direction: column;
    width: 80%;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    font-family: 'PT Sans', sans-serif;
    padding-bottom: 70px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.profit__list-item-image{
    width: fit-content;
    margin-bottom: 30px;
}
.shortabout__container{
    padding-top: 30px;
    width: 100%;
    background-color: rgba(1,1,1,0);
}
@media(max-width:1000px){
    .profit__list{
        display: grid;
        display: grid; 
        grid-template-columns: repeat(2, 1fr); 
        grid-template-rows: 1fr; 
        gap: 0px 0px; 
        background-image: none;
}
}

@media(max-width:580px){
    .shortabout__tittle{
        font-size: 24px;
        width:300px;
    }
    .profit__list-item{
       display: flex;
       flex-direction: row;
       flex-wrap: nowrap;
       align-items: center;
    }
    .profit__list{
        display: grid;
        display: grid; 
        grid-template-columns: repeat(1, 1fr); 
        grid-template-rows: 1fr; 
        gap: 0px 0px; 
        
    }
    .profit__list-item div{
        margin-left: 20px;
    }

}