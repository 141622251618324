@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.footer__container{
    height: 85px;
    background: #7a7a7a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
}
.footer__left{
    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    color: #ecedf0;
    margin-left: 100px;
}
.footer__link{
    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    color: #ecedf0;
}
.footer__right{
    margin-right: 100px;
}

@media(max-width:700px){
    .footer__left{
        margin-left: 50px;
    }
    .footer__right{
        margin-right: 50px;
    }
}