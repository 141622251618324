@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@600&family=PT+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');

.kompressory__container{
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    background-color: #f6f7fa;
}
.gS__container-item2{
    width: fit-content;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    font-size: 17px;
    border: 2px solid #ecedf0;
    width: 300px;
}
.kompressory__tittle{
    margin-top: 0px;
    font-family: 'PT Sans', sans-serif;
    font-weight: 500;
    padding-top: 10px;
    font-weight: 700;
    font-size: 38px;
    padding-bottom: 15px;
}
.manipulatory__tittle{
    margin-top: 0px;
    font-family: 'PT Sans', sans-serif;
    font-weight: 500;
    padding-top: 10px;
    font-weight: 700;
    font-size: 38px;
    padding-bottom: 15px;
}
.kompressory__wrapper{
    width: 80%;
    margin: auto;
    font-family: 'PT Sans', sans-serif; 
}
.manipulatory__wrapper{
    width: 80%;
    margin: auto;
    font-family: 'PT Sans', sans-serif; 
}
.generatory__pluslist-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-self: center;
    max-width: 150px;
}
.eleckompressor_warning{
    margin-bottom: 10px;
    margin-top: 10px;
}
.generatory__pluslist-item p{
    text-align: center;
}
.generatory__pluslist{
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows:  repeat(2,1fr);
    margin: auto;
    margin-top: 40px;
}
