@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.FM__wrapper{
    margin: auto;
    font-family: 'PT Sans', sans-serif;   
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    padding-top: 30px;
}
.FM__item{
    padding: 5px;
}
.FM__tittle{
    font-weight: 500;
    font-size: 26px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px
}
.FM__item-tittle{
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
}
.FM__item-text{
    color: #717681;
    font-size: 16px;
    padding: 10px;
}
.notfound__item_p{
    margin-bottom: 20px
}