@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@600&family=PT+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.mobilemenu__wrapper.active{
    width: 100%;
    height: 1000px;
    position: absolute;
    z-index: 200;
    background-color: #222225;
    scale: 1;
    transition: 0.3s;
}
.mobilemenu__wrapper{
    scale: 0;
    height: 0;
    transition: 0.5s;
}
.catalogmenu__list-item{
    
    display: flex;
    align-items:center ;
    justify-content: center;
    line-height: 1.5;
    border-bottom: 0;
    border-top: 0px;    
    background-color: #2d2d30;
    height: 60px;
    font-family: 'PT Sans', sans-serif;
    text-decoration: none;
    font-size: 1.3rem;
    color: #212529;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding-left: 20px;
    transition: 0.5s;
}
.catalogmenu__list-item:hover{
    background-color:#39393a ;
}
.catalogmenu__list{

    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.catalogmenu__container{
    background-color: #ecedf0;
    width: 100%;
    height: 240px;
}
.catalogmenu__list-item-link{
    text-decoration: none;
    color: #212529;
    width: 100%;
    text-align: start;
    padding: 20px 0px ;
    display: flex;
    justify-content: flex-start;
    display: flex;
    color: #fff;
}
.contactsmenu__links-item{
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    font-family: 'PT Sans', sans-serif;
    padding: 5px 0;     
    color: #b2b6be;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    transition: 0.3s;

}
.contactsmenu__links-item:hover{
    color: #fdfdfd;

}
.contactsmenu__links{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    
    padding-left: 20px;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    padding-top: 50px;
    width: 80%;
    padding-bottom:40px ;
}
.mobilemenu__adresscontainer{
    padding-left: 20px;
    color: #b1b6bf;
    font-size: 13px;
    font-family: 'PT Sans', sans-serif;
    line-height: 3;
    margin-bottom: 50px;
}
.mobilemenu__adresscontainer-item a{
    color: #b1b6bf;
}
.mobilemenu__button{
    text-align: center;
    width: 100%;
    padding: 20px 10px;
    background-color: #dddddd;
    font-family: 'PT Sans', sans-serif;
    font-size: 20px;
    transition: 0.3s;
}
.mobilemenu__button:hover{
    background-color: #f4f3f3;
}
@media(max-width:550px){
    .contactsmenu__links{
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-row-gap:10px;
        padding-bottom: 5px;
        padding-top: 5px;
    }
    .mobilemenu__adresscontainer{
        margin-bottom: 10px;
    }
}