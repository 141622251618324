@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@600&family=PT+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.about__list{
    width: 80%;
    display: flex;
    flex-wrap: wrap;
}
.about__container{
    background-color: #f6f7fa;
}
.about__list-item{
    width: 45%;
    margin-left: 40px;
    padding-top: 20px;
}
.about__tittle{
    margin-top: 0px;
    font-family: 'PT Sans', sans-serif;
    font-weight: 500;
    padding-top: 10px;
    font-weight: 700;
    font-size: 38px;
    padding-bottom: 15px;
    margin-left: 10%;
}
.about__wrapper{
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
}
.about__list-item-tittle{
    font-family: 'PT Sans', sans-serif;
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 30px;
}
.about__list-item-text{
    font-size: 15px;
    line-height: 26px;
    color: #717681;
    padding-bottom: 30px;
}
.about__list-item-button{
    width: 204px;
    height: 60px;
    color: #898989;
    line-height: 60px;
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    display: inline-block;
    border: 1px solid #b2b6be;
    text-decoration: none;
    transition: 0.3s;
}
.about__list-item-button:hover{
    color: #2ea363;
}
@media(max-width:500px){
    .about__list-item{
        width: 60%;
    }
}