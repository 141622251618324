@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@600&family=PT+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.gS__item__top1{

    border-bottom: 1px solid #ecedf0;
    height: 175px;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 250px;
    overflow: hidden;
}
.podemniki__tittle{
    margin-top: 0px;
    font-family: 'PT Sans', sans-serif;
    font-weight: 500;
    padding-top: 10px;
    font-weight: 700;
    font-size: 38px;
    padding-bottom: 15px;
}
.podemniki__subtittle{
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: 'PT Sans', sans-serif;
}
.selltech__tittle{
        margin-top: 0px;
    font-family: 'PT Sans', sans-serif;
    font-weight: 500;
    padding-top: 10px;
    font-weight: 700;
    font-size: 38px;
    padding-bottom: 15px;
}
.selltech__wrapper{
    width: 80%;
    margin: auto;
    font-family: 'PT Sans', sans-serif; 
}
.gs__item__top-pic1{
    height: 170px;
}