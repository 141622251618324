@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.workinfo__list{
    display: flex;
    margin-bottom: 30px;
    text-align: center;
    align-items: center;
    font-family: 'PT Sans', sans-serif;
}
.workinfo__list-item{
    padding-left: 5px;
    padding-right: 5px;
}
.workinfo__block{
    display: flex;
    justify-content: center;
}
.workinfo__tittle{
    display: flex;
    justify-content: center;
    background: #f6f7fa;
    font-weight: 500;
    font-size: 26px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'PT Sans', sans-serif;
}
.workinfo__list-item-tittle{
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
}

.workinfo__container{
    width: 80%;
    margin: auto;
    padding-bottom: 30px;
    padding-top: 30px;
}
.Articles__wrapper .workinfo__container{
    width: 100%;
}
.workinfo__wrapper{
    background-color: #f6f7fa;
}
.workinfo__list-item-text{
    color: #717681;
}
@media(max-width:580px){
    .workinfo__list{
        display: flex;
        flex-direction: column;
    }
}
.workinfo__list-item-pic img{
    width: 70px;
    height: 70px;
    
}