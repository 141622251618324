@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.aboutus__container {
  width: 80%;
  margin: auto;
  display: grid; 
  grid-auto-columns: 1fr; 
  grid-auto-rows: 1fr; 
  grid-template-columns: 1.2fr 0.8fr; 
  grid-template-rows: 0.8fr 1fr; 
  gap: 0px 100px; 
  grid-template-areas: 
    "aboutus__tittle aboutus__list"
    "aboutus__text aboutus__list"; 
    padding: 45px 0;
}
.aboutus__tittle {
   grid-area: aboutus__tittle; 
  padding-right: 0px;
  font-size: 22px;
  line-height: 32px;
  font-family: 'Ysabeau SC', sans-serif;
}
.aboutus__text {
   grid-area: aboutus__text;
   color: #717681;
   width: 100%;
   padding-right: 0px;
   font-size: 15px;
   line-height: 22px;
}
.aboutus__list { 
  grid-area: aboutus__list; 
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.aboutus__list-item{
  display: flex;
  align-items: center;
}
.aboutus__list-item-number{
    color: #b2b6be;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 700;
    font-size: 34px;
    padding-right: 20px;
}
.aboutus__list-item-text{
  color: #0b162b;
  font-family: 'Ysabeau SC', sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  align-self: center;
}
.aboutus__text-st{
  font-family: 'Ysabeau SC', sans-serif;  
}
.aboutus__text-nd{
  font-family: 'Ysabeau SC', sans-serif;
}
@media(max-width:1000px){
  .aboutus__container {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr; 
    grid-template-rows: 0.4fr 1fr 1fr; 
    gap: 20px 100px; 
    grid-template-areas: 
      "aboutus__tittle"
      "aboutus__text"
      "aboutus__list"; 
  }
  .aboutus__tittle { grid-area: aboutus__tittle; }
  .aboutus__text { grid-area: aboutus__text; }
  .aboutus__list { 
    grid-area: aboutus__list;
    display: grid;
    grid-auto-columns: 1fr; 
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: repeat(2, 1fr); 
    gap: 0px 50px; 
  }
}
@media(max-width:700px){
  .aboutus__list { 
    grid-area: aboutus__list;
    display: grid;
    grid-auto-columns: 1fr; 
    grid-template-columns: repeat(1, 1fr); 
    grid-template-rows: repeat(1, 1fr); 
    gap: 0px 50px; 
    
  }
  .aboutus__list-item-text{
    font-size: 16px;
  }
}