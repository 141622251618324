@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@600&family=PT+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
.PP__container{
    width: 80%;
    margin: auto;
    font-size: 16px;
    font-family: 'PT Sans', sans-serif;
    padding-bottom: 40px;
    padding-top: 50px;
}
.PP__text-item{
    margin-bottom: 1rem;
}
.PP__tittle{
    margin-top: 0px;
    font-family: 'PT Sans', sans-serif;
    font-weight: 500;
    padding-top: 10px;
    font-weight: 700;
    font-size: 38px;
    padding-bottom: 15px;
}
.PrivacyPolitics{
    background-color: #f6f7fa;
}
.PP__text-list{
    list-style: none;
    padding-left: 30px;
    margin-bottom: 1rem;
}
@media(max-width:450px){
    .PP__tittle{
        font-size: 25px;
    }
}