@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ysabeau+SC:wght@400;500;600&display=swap');
*{
    margin: 0;
    padding: 0;
}
#app{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
body{
    overflow-x: hidden;
    background-color: #f6f7fa;
} 
.header__space{
    height: 90px;
}
.appdiv{
    flex-grow: 1;
}
button{
    background-color: transparent;
    border: 0;
}
div{
    transition: 0.3s;
}